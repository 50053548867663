import { useEffect, useState } from "react";
import { UilAngleLeftB, UilAngleRightB, UilExpandArrows, UilExpandArrowsAlt, UilMultiply, UilWindowMaximize } from '@iconscout/react-unicons'

export default function ImageView(obj) {

    const [mainImage, setMainImage] = useState(obj.images[0].large);
    const [modalOpen, setModalOpen] = useState(false)

    const [index, setIndex] = useState(0)

    return (
        <div>
        {modalOpen && 
        <div className="fixed z-10 py-8 px-4 md:px-8 xl:px-20 w-full h-screen bg-black/50 left-0 top-0">
            <div className="flex flex-row z-20 relative w-full h-full bg-white rounded-xl">
                <button onClick={()=> setModalOpen(false)} className="absolute z-10 text-black right-4 top-4 bg-gray-200 p-2 rounded-full"><UilMultiply size="24"/></button>
                <button onClick={()=> setIndex(index === 0 ? obj.images.length-1 : index-1)} className="flex my-auto ml-4 mr-auto bg-gray-200 p-2 rounded-full"><UilAngleLeftB size="24"/></button>
                <div className="flex p-4 w-full h-full">
                    <div className="flex m-auto w-full h-full">
                        <img className="mx-auto object-contain" src={obj.images[index].hiRes}/>
                    </div>
                </div>
                <button onClick={()=> setIndex(index === obj.images.length-1 ? 0 : index+1)} className="flex my-auto ml-auto mr-4 bg-gray-200 p-2 rounded-full"><UilAngleRightB size="24"/></button>
            </div>
        </div>
        }
        <div className="mx-auto flex flex-col gap-8 sm:w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%]">
            <button onClick={() => setModalOpen(true) } className="flex relative aspect-square w-full items-center align-middle text-center mx-auto mt-8 p-8 ring-offset-8 ring-1 ring-gray-300 rounded-2xl">
                <div className="flex aspect-square m-auto items-center object-scale-down">
                    <img className="object-contain mx-auto" src={mainImage}/>
                </div>
                <div className="absolute right-2 top-2 p-2 bg-gray-200 rounded-full"><UilExpandArrowsAlt size={20}/></div>
            </button>
            <div className="mx-auto grid grid-cols-6 gap-2 flex-wrap">
                {obj.images.map((image, index) => {
                    if(mainImage === image.large) {
                        return (
                            <button className="flex duration-200 aspect-square rounded-md outline-2 outline outline-black p-2">
                                <img className="object-contain mx-auto" src={image.thumb}/>
                            </button>
                        )
                    }
                    else {
                        return (
                            <button 
                            className="flex duration-200 aspect-square rounded-md outline-1 outline outline-gray-400 p-2 hover:outline-black"
                            onClick={()=> {setMainImage(image.large); setIndex(index)}}
                            >
                                <img className="object-contain mx-auto" src={image.thumb}/>

                            </button>
                        )
                    }
                })}
            </div>
        </div>
        </div>
    );
}