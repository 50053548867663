import React from 'react';


import Header from './components/header/header';

import SearchCategory from './components/search-category';
import PagesProducts from './components/pagesproducts';
import Announcement from './components/header/announcement';
import AnnouncementNew from './components/header/announcement-new';

function Main(args) {

  /*return (
    <section>
    <Banner></Banner>
    <PageBody></PageBody>
    </section>
);*/

return (
  <div className="">
        <Announcement/>
        <AnnouncementNew/>
        <div className='relative  z-15'>
          <Header page="acasa"/>
        </div>
        <div className='relative  z-10'>
          <SearchCategory productPath={args.productPath} categoryPath={args.categoryPath}/>
        </div >
        <div className='relative z-0'>
          <PagesProducts productPath={args.productPath}/>
        </div>
        <div className="bg-black w-full flex p-2">
                <span className="text-white text-sm items-center m-auto">Copyright © 2024 Crangeio S.R.L.</span>
            </div>
  </div>
);

//lg:sticky lg:top-0
    
}
export default Main;

/*

return (
        <section className="page-body">
          <Header></Header>
          <section className="product-list">
            <section className="placeholder-up"></section>
            <PageContainerUp></PageContainerUp>
            <SearchBar></SearchBar>
            <CategoryContainer> </CategoryContainer>
            <ProductContainer> </ProductContainer>
            <section className="placeholder-down"></section>
            <PageContainerDown></PageContainerDown>
          </section>
        </section>
    );

*/
