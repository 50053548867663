
export default function AnnouncementNew () {
    return (
        <div className="flex w-full bg-header">
            <div className="flex m-auto p-10 pb-2 items-center text-center text-sm md:text-base">
                <div className="flex p-4 bg-white rounded-lg text-black flex-col">
                    <span>
                        Pentru a afla noutăți legate de produsele din magazin, <a href="https://www.facebook.com/profile.php?id=61556917665673" className="font-semibold underline">urmăriți-ne pe Facebook</a>!
                    </span>
                </div>
            </div>
        </div>
    )
}