import { locationRoot } from "../../data/variables";
import { UilEstate } from '@iconscout/react-unicons'
import { UilQuestionCircle } from '@iconscout/react-unicons'
import { UilExclamationCircle } from '@iconscout/react-unicons'
import { UilMapMarker } from '@iconscout/react-unicons'
import AnnouncementNew from "./announcement-new";

function Header (args) {
    return (
        <div className="flex flex-col lg:flex-row gap-4 xl:gap-8 object-center text-center py-10 bg-header">
            <div className="order-1 lg:order-2 m-auto lg:ml-0 lg:mr-0 sm:px-0 sm:w-3/4 md:w-1/2 lg:w-1/4 px-8">
                <a href={locationRoot+"/"} className="flex p-2 px-4 rounded-2xl  shadow-lg bg-white m-auto ">
                    <img className="text-white h-16 md:h-24 m-auto" src="/Logo-color.svg" alt="Logo" ></img>
                </a>
            </div>
            <div className=" order-2 lg:order-1 grid grid-rows-2 gap-4 m-auto lg:mr-0 ml-auto  w-[75%] md:w-[50%] lg:w-[25%] xl:w-[25%] 2xl:w-[15%]">
                {(args.page==="acasa" && 
                
                <div className="duration-200 bg-white text-black py-2 px-8 flex gap-2 flex-row rounded-full text-sm md:text-base"> 
                    <div className="my-auto"><UilEstate size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">Acasă</span>
                </div>) || 

                <a href={locationRoot+"/"} className="duration-200 hover:bg-black hover:text-white py-2 px-8 flex gap-2 flex-row rounded-full bg-category text-white text-sm md:text-base">
                    <div className="my-auto"><UilEstate size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">Acasă</span>
                </a>

                }
                
                {(args.page==="de-ce-retururi" && 
                
                <div className="duration-200 bg-white text-black py-2 px-8 flex gap-2 flex-row rounded-full text-sm md:text-base"> 
                    <div className="my-auto"><UilQuestionCircle size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">De ce retururi?</span>
                </div>
                
                ) || 

                <a href={locationRoot+"/de-ce-retururi"} className="duration-200 hover:bg-black hover:text-white py-2 px-8 flex gap-2 flex-row rounded-full bg-category text-white text-sm md:text-base">
                    <div className="my-auto"><UilQuestionCircle size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">De ce retururi?</span>
                </a>

                }

            </div>
            <div className="order-3 grid grid-rows-2 gap-4 m-auto lg:ml-0 mr-auto w-[75%] md:w-[50%] lg:w-[25%] xl:w-[25%] 2xl:w-[15%]">
                
                {(args.page==="informatii" && 
                
                <div className="duration-200 bg-white text-black py-2 px-8 flex gap-2 flex-row rounded-full text-sm md:text-base"> 
                    <div className="my-auto"><UilExclamationCircle size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">Informații importante</span>
                </div>
                
                ) || 

                <a href={locationRoot+"/informatii-importante"} className="duration-200 hover:bg-black hover:text-white py-2 px-8 flex gap-2 flex-row rounded-full bg-category text-white text-sm md:text-base">
                    <div className="my-auto"><UilExclamationCircle size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">Informații importante</span>
                </a>

                }

                {(args.page==="unde-ne-gasesti" && 
                
                <div className="duration-200 bg-white text-black py-2 px-8 flex gap-2 flex-row rounded-full text-sm md:text-base"> 
                    <div className="my-auto"><UilMapMarker size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">Unde ne găsești?</span>
                </div>
                
                ) || 

                <a href={locationRoot+"/unde-ne-gasesti"} className="duration-200 hover:bg-black hover:text-white py-2 px-8 flex gap-2 flex-row rounded-full bg-category text-white text-sm md:text-base">
                    <div className="my-auto"><UilMapMarker size="20"/></div>
                    <span className="text-sm md:text-base m-auto truncate">Unde ne găsești?</span>
                </a>

                }
                
            </div>

        </div>
    )
}

/*
<div className="website-title-container">
            <a className="website-title" href={locationRoot+"/"}>Stockzilla</a>
          </div>

          <a className="text-white text-5xl sm:text-6xl md:text-7xl lg:text-7xl font-black" href={locationRoot+"/"}>Stockzilla</a>
*/

export default Header;