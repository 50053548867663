import { locationRoot } from "../data/variables";
import { UilCheckCircle } from '@iconscout/react-unicons'
import { UilTimesCircle } from '@iconscout/react-unicons'

function ProductCard (id, lpn, title, price, mainImage, stoc, featured) {
    /*return (
        <a className="flex-col object-center rounded-3xl sm:rounded-sm md:rounded-md container mx-auto bg-white" href={locationRoot+"/product/?id="+id}>
          <div className="">
            <img src={mainImage} alt="img"></img>
          </div>
          <span className="title">
            {title}
          </span>
          <div className = "">
            <div className="">
              {(((parseFloat(price) * 4.9) * 50)/100).toFixed(2)} RON
            </div>
            <div className="">
              {(parseFloat(price) * 4.9).toFixed(2)} RON
            </div> 
          </div>
        </a>
        )*/
        return (
          <a href={locationRoot+"/product/?id="+lpn}>
            <div className="h-full duration-200 hover:ring-4  hover:ring-category group flex flex-col w-full gap-2 p-6 mx-auto shadow-lg select-none rounded-2xl bg-white" href={locationRoot+"/product/?id="+id}>
                
                {((featured===1 && stoc===1) && 
                
                <div className="relative flex flex-row rounded-md w-full bg-black text-white text-sm p-2 gap-0"> 
                  <span className="m-auto"> Adăugat recent! </span>
                </div>)

                || 

                <div className="relative flex flex-row rounded-md w-full bg-gray-200 text-gray-600 text-sm p-2 gap-0"> 
                  {stoc===1 && <span className="m-auto text-xs text-center">În stoc</span>}
                  {stoc===0 && <span className="m-auto text-xs text-center">Vândut</span>}
                  {stoc===1 && <div className="absolute text-category right-2"><UilCheckCircle size="20"/></div>}
                  {stoc===0 && <div className="absolute text-red-500 right-2"><UilTimesCircle size="20"/></div>}
                </div>
                
                }
                
                
                <div class="flex rounded-lg items-center mx-auto">
                    <div className="aspect-square w-full flex p-4">
                      {stoc===1 && <img src={mainImage} class="aspect-square object-contain m-auto h-full" alt="product"></img>}
                      {stoc===0 && <img src={mainImage} class="opacity-50 aspect-square object-contain m-auto h-full" alt="product"></img>}
                    </div>
                </div>
                
                <div class="  flex flex-col flex-1 gap-2 mt-auto">
                  <div class="flex items-center align-middle flex-col flex-1 gap-2">
                    <span class="mx-auto text-center w-full truncate">{title}</span>
                  </div>
                  {stoc===1 && <div class="flex flex-col duration-200 group-hover:bg-gray-200 group-hover:text-black p-2 rounded-lg">
                    <span class="m-auto font-extrabold">
                      {(((parseFloat(price) * 5) * 50)/100).toFixed(2)} RON
                    </span>
                    <span class="m-auto text-category font-semibold text-xs text-center">
                    Amazon: {(parseFloat(price) * 5).toFixed(2)} RON
                    </span>
                  </div>}
                  {stoc===0 && <div class="flex flex-col duration-200 p-2 rounded-lg">
                    <span class="m-auto font-extrabold text-gray-300">
                      {(((parseFloat(price) * 5) * 50)/100).toFixed(2)} RON
                    </span>
                    <span class="m-auto text-xs text-gray-300 text-center">
                    Amazon: {(parseFloat(price) * 5).toFixed(2)} RON
                    </span>
                  </div>}
                </div>
            </div>
            </a>
            )
}

export default ProductCard;